import React, { useState } from "react";
import {
  Seo,
  CarouselHeader,
  Card,
  BenefitsButton,
  Recommendations,
  BelowFoldBanner,
} from "components";
import {
  colors,
  breakpoints,
  easeTransition,
  Section,
  Container,
  FullWidthContent,
  Cards,
  H2,
  H5,
  P,
} from "styles";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import styled from "styled-components";
import {
  Arrow,
  Pharmacy,
  Target,
  Timer,
  DrugFree,
  CheckmarkCircled,
  Flame,
  Brain,
  HeatCell,
  BloodCirculation,
  BackMuscles,
  CEMark,
} from "assets/svgs";

// markup
const IndexPage = ({ data }) => {
  return (
    <>
      <Seo title="Homepage" />
      <CarouselHeader data={data} />
      <Section $bg={colors.darkRed}>
        <BelowFoldBanner />
      </Section>
      <Section>
        <Container style={{ gridGap: "1.625rem" }}>
          <ProductsImage>
            <GatsbyImage
              image={getImage(data.productImage)}
              alt="Products"
              objectFit="contain"
              objectPosition="center"
            />
            <CEMark />
          </ProductsImage>
          <ProductsDetails>
            <Pharmacy />
            <H2>ThermaCare® is available in two variants</H2>
            <P>
              ThermaCare® clinically proven heat wraps provide constant heat
              which increases blood flow to tissues, giving analgesic effects
              which last even after the wrap has been removed. As muscles relax,
              more oxygen is provided to the damaged tissues, helping to restore
              aching muscles and joints.
            </P>
            <P style={{ marginBottom: "2rem" }}>
              The following ThermaCare® products are available and specifically
              designed to fit different areas of pain in the body.{" "}
            </P>
            <ProductsLink to="/products/thermacare-lower-back-and-hip">
              <Arrow />
              <p>ThermaCare® Lower back & hip</p>
            </ProductsLink>
            <ProductsLink to="/products/thermacare-neck-and-shoulders">
              <Arrow />
              <p>ThermaCare® Neck, shoulder and wrist</p>
            </ProductsLink>
            <Supported>
              <GatsbyImage image={getImage(data.supported)} alt="ISCP" />
            </Supported>
          </ProductsDetails>
        </Container>
      </Section>
      <Section $bg={colors.darkRed}>
        <Container $smallPadding>
          <FullWidthContent>
            <H5 $center $noMargin $color="white">
              Clinical trials show that ThermaCare heat wraps are more effective
              than the maximum daily dosage of either Paracetamol or Ibuprofen
              for long lasting pain relief.
            </H5>
          </FullWidthContent>
        </Container>
      </Section>
      <Section $bg={colors.lightGrey}>
        <Container>
          <FullWidthContent>
            <H2 $center>What makes ThermaCare® unique?</H2>
          </FullWidthContent>
          <Cards>
            <Card
              svg={<Target />}
              title="Clinically proven pain relief"
              text="Acts locally with no systemic side effects while providing reduction in muscle stiffness and improved flexibility"
            />

            <Card
              svg={<Timer />}
              title="Continuous heat"
              text="Up to 8 hours of continuous deep penetrating heat and up to 16 hours of pain relief"
            />

            <Card
              svg={<DrugFree />}
              title="Drug free heat wraps"
              text="Clinically proven to provide targeted long lasting pain relief for up to 16 hours. ThermaCare® provides therapeutic muscle penetrating heat to the affected areas."
            />

            <Card
              svg={<CheckmarkCircled />}
              title="Easy to use"
              text="Odourless, discreet and flexible with unique closure system for patient comfort"
            />
          </Cards>
        </Container>
      </Section>
      <Section $bg={colors.darkRed}>
        <Container $smallPadding>
          <FullWidthContent>
            <H5 $center $noMargin $color="white">
              Clinically Proven
              <Span $color={colors.orange} />
              Therapeutic muscle penetrating heat
              <Span $color={colors.orange} />
              Long lasting pain relief
            </H5>
          </FullWidthContent>
        </Container>
      </Section>
      <Section>
        <Container>
          <FullWidthContent>
            <H2 $center style={{ marginBottom: "5rem" }}>
              How ThermaCare® works?
            </H2>
          </FullWidthContent>
          <HowItWorks>
            <StaticImage
              src="../assets/images/aches-and-pains.png"
              alt="How Thermacare Works"
              placeholder="blurred"
              layout="fullWidth"
              loading="lazy"
              objectFit="contain"
              objectPosition="center"
            />
            <BenefitsButton
              svg={<BackMuscles />}
              title="Muscles relaxing"
              text="ThermaCare® heat wraps helps relax damaged, tight, and sore muscles, improves flexibility."
              top="80%"
              left="49%"
              width="22rem"
            />
            <BenefitsButton
              svg={<HeatCell />}
              title="ThermaCare® Patented Heat Cell Technology:"
              text="As soon as the pouch is opened, oxygen penetrates into the heat cells. The chemical reaction is triggered and commences producing heat. Constant temperature of approximately 40°C is generated, thanks to the continuous oxygen flow controlled by the membranes."
              top="58%"
              left="48%"
              width="22rem"
            />
            <BenefitsButton
              svg={<Flame />}
              title="Long lasting heat"
              text="Thermacare is clinically proven to provide therapeutic
              muscle penetrating heat and up to 16 hours pain relief.
              That's up to 8 hours while you wear the heat wrap plus an
              additional 8 hours of pain relief after you take it off."
              top="39%"
              left="40%"
              width="25rem"
            />
            <BenefitsButton
              svg={<BloodCirculation />}
              title="Increased blood
              circulation"
              text="The ThermaCare® provides constant heat which increases blood flow to tissues, giving analgesic effects which last even after the wrap has been removed. As muscles relax, more oxygen is provided to the damaged tissues, helping to restore aching muscles and joints."
              top="37%"
              left="54%"
              width="22rem"
            />
            <BenefitsButton
              svg={<Brain />}
              title="Pain relieving"
              text="ThermaCare® Heat stimulates thermoreceptors thus blocking pain signals to the brain."
              top="5%"
              left="47%"
              width="22rem"
            />
          </HowItWorks>
        </Container>
      </Section>
      <Section>
        <Recommendations />
      </Section>
      <Section>
        {/* <Container>
          <Testimonials />
        </Container> */}
      </Section>
    </>
  );
};

const HowItWorks = styled(FullWidthContent)`
  overflow: visible;
  position: relative;
  z-index: 1;
  .gatsby-image-wrapper {
    width: 100% !important;
  }
`;

const Span = styled.span`
  background: ${({ $color }) => $color};
  display: block;
  height: 1px;
  margin: 1rem auto;
  width: 20%;
  ${breakpoints.md} {
    display: inline-block;
    height: 1em;
    margin: 0 2rem;
    vertical-align: middle;
    width: 0.125rem;
  }
`;

const ProductsLink = styled(Link)`
  align-items: center;
  color: ${colors.red};
  display: flex;
  font-size: 1rem;
  font-weight: 500;
  line-height: 2em;
  text-decoration: underline;
  transition: ${easeTransition};
  position: relative;
  &:hover {
    color: ${colors.darkRed};
  }
  svg {
    position: relative !important;
    margin-right: 0.75rem;
    transform: rotate(90deg);
    height: 0.725rem !important;
    width: 0.725rem !important;
  }
`;

const ProductsDetails = styled.div`
  grid-column: 1/7;
  position: relative;
  ${H2} {
    margin-bottom: 1.5rem;
    width: 70%;
  }
  svg {
    height: 5rem;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
  ${breakpoints.sm} {
    grid-column: 4/7;
  }
  ${breakpoints.lg} {
    grid-column: 7/12;
    svg {
      height: 6rem;
    }
  }
`;

const ProductsImage = styled.div`
  position: relative;
  grid-column: 1/7;
  margin-bottom: 2rem;
  svg {
    position: absolute;
    bottom: -2rem;
    right: calc(50% - 16px);
  }
  ${breakpoints.sm} {
    grid-column: 1/4;
    svg {
      bottom: 12rem;
      right: calc(50% - 24px);
    }
  }
  ${breakpoints.lg} {
    grid-column: 2/7;
    svg {
      bottom: -2rem !important;
      right: calc(50% - 38px);
    }
  }
  ${breakpoints.xl} {
    svg {
      bottom: 0;
    }
  }
`;

const Supported = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  margin-top: 2rem;
  .gatsby-image-wrapper {
    min-width: 214px;
  }
`;

export const pageQuery = graphql`
  query {
    topFlame: file(relativePath: { eq: "background-top-flame.png" }) {
      id
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 90)
      }
    }
    bottomFlame: file(relativePath: { eq: "background-bottom-flame.png" }) {
      id
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 90)
      }
    }
    productImage: file(relativePath: { eq: "products.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 90)
      }
    }
    supported: file(relativePath: { eq: "iscp.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 90)
      }
    }
  }
`;

export default IndexPage;
